<template>
    <div class="menu-side-anchor">
        <q-menu v-model="showSideMenu">
            <q-list style="min-width: 100px">
                <q-item
                    v-for="(item, index) in context"
                    :key="index"
                    v-close-popup
                    clickable
                    @click="openPage(item.alink)"
                >
                    <q-item-section side>
                        <i :class="item.foldr" />
                    </q-item-section>
                    <q-item-section>
                        {{ item.title }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </div>
</template>

<script>
export default {
    name: "SideMenu",
    props: {
        showMenu: {
            type: Number,
            required: true,
        },
        context: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showSideMenu: false,
        };
    },
    watch: {
        showMenu() {
            this.showSideMenu = true;
        },
    },
    methods: {
        openPage(pageUrl) {
            window.location = pageUrl;
        },
    },
};
</script>
